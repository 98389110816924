import Api from '../api';

class BusinessSystemService {
    static async update(systemId, name, aim, startingBoundary, endingBoundary, startingState, resultingState, isProcess) {
        let updateResult;

        const url = `/System/${systemId}`;
        try {
            // TODO: test for valid ID

            const system = {
                name: name,
                aim: aim,
                startingBoundary: startingBoundary,
                endingBoundary: endingBoundary,
                startingState: startingState,
                resultingState: resultingState,
                isProcess: isProcess
            };
            
            updateResult = await Api.update(url, system, null);
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }

        //if (!updateResult) {
        //    throw 'Error updating Business System';
       // }

        return updateResult;
    }

    static async create(companyId, parentSystemId, name, aim, startingBoundary, endingBoundary, startingState, resultingState, isProcess, insertBefore, relativeSystemId) {
        let createResult;

        const url = `/System`;
        try {
            // TODO: test for valid ID

            const system = {
                companyId: companyId,
                parentSystemId: parentSystemId,
                name: name,
                aim: aim,
                startingBoundary: startingBoundary,
                endingBoundary: endingBoundary,
                startingState: startingState,
                resultingState: resultingState,
                isProcess: isProcess
            };

            const data = {
                system: system,
                insertBefore: insertBefore,
                relativeSystemId: relativeSystemId
            }

            return await Api.create(url, data, null);
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }
    }

    static async retrieveOne(systemId) {
        const url = `/System/${systemId}`;
        return Api.retrieve(url, null, BusinessSystemService);
    }

    static async retrieveMultiple(params) {
        const url = `/Systems?text=${params?.text}`;
        const result = await Api.retrieve(url);
        return result;
    }

    static async retrieveSubsystems(systemId) {
        // TODO: consider converting to retrieveMultiple using a parentSystemId param
        const url = `/getBusinessSubsystems`;
        const config = { params: {systemId} };

        try {
            // TODO: test for valid ID

            return await Api.get(url, config);
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }
    }

    static async delete(systemId) {
        let result = null;

        const url = `/System/${systemId}`;
        try {
            // TODO: test for valid ID

            result = await Api.delete(url);
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }

        return result;
    }

    static async move(systemId, subsystemId, subsystemdisplaySequence, direction) {
        const url = `/System/${systemId}/Subsystem/${subsystemId}/move`;
        const data = { subsystemDisplaySequence: subsystemdisplaySequence, direction: direction }
        const result = await Api.create(url, data);
        return Array.isArray(result)?result:[];
    }
}

export default BusinessSystemService;