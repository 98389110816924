import React, { Component } from 'react';

class AimField extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="aim">
                <label>Aim:</label>
                <span className="field">{this.props.aim}</span>
            </div>
        );
    }
}

export default AimField;