class ApiBaseError extends Error {
    constructor(url, implementingObject) {
        super(`API base error (url=${url})`);

        // Maintains proper stack trace for where this error was thrown
        if (Error.captureStackTrace) {
        Error.captureStackTrace(this, (implementingObject || ObjectNotFoundError));
        }

        this.name = 'ApiBaseError';
        this.url = url;
    }
}

export default ApiBaseError;