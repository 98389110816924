import React from 'react';
import { Handle } from 'reactflow';
import ProcessNodeBase from '../ProcessNodeBase';
import ProcessContext from '../BusinessProcess/processContext.js';
class NvaProcessNodeDecision extends React.Component {
  constructor(props) {
      super(props);

      this.state = {};
  }

  static getDerivedStateFromProps(props, current_state) {
    if (props) {
      let newState = {
        id: props.id,
        type: props.type,
        nodeTypeId: props.nodeTypeId,
        position: props.position,
        data: props.data
      };

      if (props.data?.ynposid) {
        newState.ynposid = props.data.ynposid;
      }

      return newState;
    }

    return null
}

  getYnLabelClass = () => {
    let labelClass = '';

    switch (this.state.ynposid) {
      case '1':
        labelClass = 'right ny';
        break;
      case '2':
        labelClass = 'right yn';
        break;
      case '3':
      case '4':
        // not available for NVA decision node
    }

    return labelClass;
  }

  render() {
    return (
        <ProcessNodeBase children={this.props.children}>
          <div className="wrapper">
            
            <svg className="left">
              <path d="M0,0 150,0 0,36 150,72 0,72 0,0Z" />
            </svg>

            <svg className="right">
              <path d="M0,0 150,36 0,72 150,72 150,0 0,0Z" />
            </svg>

            
              {
                // TODO: consolidate this with label in ProcessNodeBase
                    this.props.data?.label &&
                    <ProcessContext.Consumer>
                        { context => (
                            <p className="textContent" onClick={(e) => context.handleFlowElementClick(e, this.props)}>
                            { this.props.data?.label ? this.props.data.label : 'NVA Decision'}
                            </p>
                        )}
                    </ProcessContext.Consumer>
              }
            
          </div>

          { (this.state.ynposid) && 
          <div className={'labels ' + this.getYnLabelClass()}>
            <div className="label y"><span>Y</span></div>
            <div className="label n"><span>N</span></div>
          </div>
          }
          <Handle
            type="source"
            position="bottom"
            id="b"
            style={{ background: '#555' }}
          />
          <Handle
            type="target"
            position="left"
            id="c"
            style={{ background: '#555', left: '2px' }}
          />
          <Handle
            type="source"
            position="right"
            id="d"
            style={{ background: '#555' }}
          />
        </ProcessNodeBase>
    );
  };
}

export default NvaProcessNodeDecision;