import ApiBaseError from "./ApiBase.error";

class UrlNotFoundError extends ApiBaseError {
    constructor(url, underlyingError, implementingObject) {
        super(url, (implementingObject || UrlNotFoundError));

        this.underlyingError = underlyingError;
        this.message = 'The URL specified was not found.';
    }
}

export default UrlNotFoundError;