import React, {Component} from 'react';
import { useParams } from "react-router-dom";
import BusinessSystem from '../../components/BusinessSystem'
import BusinessSystemService from '../../services/businessSystem.service';

class BusinessSystemContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            systemId: 0,
            parentSystemId: 0,
            name: '',
            aim: '',
            chosenInputs: [],
            imposedInputs: [],
            startingBoundary: '',
            startingState: '',
            desirableOutcomes: [],
            undesirableOutcomes: [],
            endingBoundary: '',
            resultingState: '',
            subsystems: [],
            feedbackLoops: [],
            isProcess: false,
            isLoading: true,
            isNotFound: false
        }
    }

    componentDidMount() {
        this.loadDataToState(this.props.params.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.id !== this.props.params.id) {
            this.loadDataToState(this.props.params.id);
        }
    }

    loadDataToState(systemId) {
        BusinessSystemService.retrieveOne(systemId)
            .then((response) => {
                if (response!==null) {
                    this.setState({
                        systemId: systemId,
                        parentSystemId: response.parentSystemId || 0,
                        name: response.name || '',
                        aim: response.aim || '',
                        subsystems: response.subsystems || [],
                        startingBoundary: response.startingBoundary || '',
                        endingBoundary: response.endingBoundary || '',
                        startingState: response.startingState || '',
                        resultingState: response.resultingState || '',
                        chosenInputs: response.chosenInputs || [],
                        imposedInputs: response.imposedInputs || [],
                        feedbackLoops: response.feedbackLoops || [],
                        desirableOutcomes: response.desirableOutcomes || [],
                        undesirableOutcomes: response.undesirableOutcomes || [],
                        isProcess: response.isProcess || false,
                        isLoading: false,
                        isNotFound: false
                    });
                }
                else {
                    console.debug('retrieveSubsystems response was null. Should probably explain to user.');
                    this.setState({
                        isLoading: false,
                        isNotFound: true
                    });
                }
            })
            .catch(function(e) { console.log('Error while retrieving business system: ', e); });
    }

    render() {
        return (
            <div className="businessSystemContainer">
                {
                    this.state.isLoading &&
                    <p>Loading Business System...</p>
                }
                {
                    this.state.isNotFound &&
                    <p>System not found</p>
                }
                {
                    !this.state.isLoading &&
                    !this.state.isNotFound &&
                    this.state.subsystems &&
                    <BusinessSystem
                        systemId={this.state.systemId}
                        parentSystemId={this.state.parentSystemId}
                        name={this.state.name}
                        aim={this.state.aim}
                        chosenInputs={this.state.chosenInputs}
                        imposedInputs={this.state.imposedInputs}
                        startingBoundary={this.state.startingBoundary}
                        startingState={this.state.startingState}
                        desirableOutcomes={this.state.desirableOutcomes}
                        undesirableOutcomes={this.state.undesirableOutcomes}
                        endingBoundary={this.state.endingBoundary}
                        resultingState={this.state.resultingState}
                        subsystems={this.state.subsystems}
                        feedbackLoops={this.state.feedbackLoops}
                        isProcess={this.state.isProcess}
                    />
                }
            </div>
        );
    }
}

const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let params = useParams();
        return (
            <Component
                {...props}
                params={params}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter(BusinessSystemContainer);