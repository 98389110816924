import React from 'react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import AuthService from '../../services/auth.service';
import PasswordConfirmValidated from '../PasswordConfirmValidated';

class SignupManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            password: '',
            passwordVerify: '',
        };
    }

    handleFormSubmit = async (event) => {
        try {
            event.preventDefault();
            this.setState({loading: true});

            let res = await AuthService.signup(this.state.username, this.state.email, this.state.password);
            if (res?.data?.status === 'success') {
                //this.context.currentUserId = payloadDecrypted.userId;

                // redirect to app
                this.setState({loading: false});
                this.props.navigate('/');

                if (this.props.handleSignup) {
                    this.props.handleSignup();
                }
            }
            else {
                this.setState({loading: false});
            }
        }
        catch (ex) {
            this.setState({loading: false});
            console.warn('error while signing up:', ex);
        }
    }

    handleInputChange = (event) => {
        const {name, value } = event.target;
        this.setState({[name]: value});
    }

    render() {
        const { t } = this.props;

        return (
            <form id="signup" onSubmit={this.handleFormSubmit}>
                <div className="formField">
                    <label htmlFor='username'>{t('auth.signup.form.username.label', 'Username')}</label>
                    <input name='username' type='text' onChange={this.handleInputChange} value={this.state.username} autoFocus={true} />
                </div>
                <div className="formField">
                    <label htmlFor='email'>{t('auth.signup.form.email.label', 'Email')}</label>
                    <input name='email' type='text' onChange={this.handleInputChange} value={this.state.email} autoFocus={true} />
                </div>
                <PasswordConfirmValidated name="password" onChange={this.handleInputChange} />

                <button disabled={this.state.loading} type='submit'>
                    {this.state.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>{t('auth.signup.form.button', 'Sign up')}</span>
                </button>
                {
                    this.state.unauthorizedMessage &&
                    <span className='validationMessage'>{t('auth.signup.form.unauthorizedMessage', 'Credentials are not valid.')}</span>
                }
            </form>
        );
    }
}

export default withTranslation()(function(props) {
    const navigate = useNavigate();

    return <SignupManager {...props} navigate={navigate} />;
});