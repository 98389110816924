import React from 'react';
import { Handle } from 'reactflow';
import ProcessNodeBase from '../ProcessNodeBase';
class ProcessNodeStart extends React.Component {
  constructor(props) {
      super(props);
  }

  render() {
    return (
      <ProcessNodeBase {...this.props} data={{...this.props.data, label: this.props.data?.label ? this.props.data.label : 'Start'}}>
          <Handle
            type="source"
            position="bottom"
            id="a"
            style={{ background: '#555' }}
          />
        </ProcessNodeBase>
      );
  };
}

export default ProcessNodeStart;