import React from 'react';
import { Handle } from 'reactflow';
import ProcessNodeBase from '../ProcessNodeBase';
import ExternalLink from './externalLink.js';

class ProcessNodeStep extends React.Component {
  constructor(props) {
      super(props);
  }

  render() {
    return (
        <ProcessNodeBase {...this.props} data={{...this.props.data, label: this.props.data?.label ? this.props.data.label : 'Step'}}>
          <Handle
            type="target"
            position="top"
            id="a"
            style={{ background: '#555' }}
          />
          <Handle
            type="source"
            position="bottom"
            id="b"
            style={{ background: '#555' }}
          />
          { this.props.data?.externalUrl &&
            <ExternalLink url={this.props.data?.externalUrl} />
          }
        </ProcessNodeBase>
      );
  };
}

export default ProcessNodeStep;