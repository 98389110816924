import React from 'react';
import { Link } from 'react-router-dom';

class HoverMenuItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { children, linkTo, onClick, tipText } = this.props;
        return (
            <li className="hoverMenuItem">
                {linkTo &&
                    <Link to={linkTo}>{children}</Link>
                }

                {onClick &&
                    // TODO: react "object.keys" to pass certain attributes to child using spread
                    <span onClick={onClick} data-id={this.props["data-id"]}>{children}</span>
                }

                {tipText &&
                    <span className='toolTip'>{tipText}</span>
                }
            </li>
        );
    }
}

export default HoverMenuItem;