import React from "react";
import BusinessSystemService from '../../services/businessSystem.service';
import Modal from '../Modal';

class BusinessSystemNewModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: props.name ? props.name : "",
            aim: props.aim ? props.aim : "",
            startingBoundary: props.startingBoundary ? props.startingBoundary : "",
            endingBoundary: props.endingBoundary ? props.endingBoundary : "",
            startingState: props.startingState ? props.startingState : "",
            resultingState: props.resultingState ? props.resultingState : "",
            isProcess: props.isProcess ? props.isProcess : 0
        }
    }

    getDefaultState = () => {
        return {
            name: "",
            aim: "",
            startingBoundary: "",
            endingBoundary: "",
            startingState: "",
            resultingState: "",
            isProcess: 0
        }
    }
    
    handleInputChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({isProcess: e.target.checked ? 1 : 0});
        }
        else {
            const {name, value } = e.target;
            this.setState({[name]: value});
        }
    }

    handleCancel = (e) => {
        this.setState(this.getDefaultState());
        this.props.setShow(false);
        e.stopPropagation();
    }

    handleCreate = async (e) => {
        e.preventDefault();
        
        try {
            const newSystem = await BusinessSystemService.create(
                this.props.companyId,
                this.props.parentSystemId,
                this.state.name,
                this.state.aim,
                this.state.startingBoundary,
                this.state.endingBoundary,
                this.state.startingState,
                this.state.resultingState,
                this.state.isProcess,
                this.props.insertBefore,
                this.props.relativeSystemId);

            if (newSystem && newSystem.systemId > 0) {
                await this.props.handleUpdate(this.state, isModalVisible, newSystem, this.props.insertBefore, this.props.relativeSystemId);

                const isModalVisible = false;
                this.props.setShow(isModalVisible);

                this.setState(this.getDefaultState());
            }
            else {
                // TODO: pass message along to user

                console.log('system create failed in BusinessSystemNewModal');
            }
        }
        catch(ex) {
            console.log('Unhandled error in newModal.js. ' + ex)
        }
    }

    render() {
        return (
            <Modal title='New System' isOpen={this.props.show} dismiss={this.handleCancel} action={this.handleCreate} actionText='Create'>
                    <div className="formField"><label>Name:</label><textarea name="name" className="field" value={this.state.name} onChange={this.handleInputChange} autoFocus={true} /></div>
                    <div className="formField"><label>Aim:</label><textarea name="aim" className="field" value={this.state.aim} onChange={this.handleInputChange} /></div>
                    <div className="formField"><label>Starting Boundary:</label><textarea name="startingBoundary" className="field" value={this.state.startingBoundary} onChange={this.handleInputChange} /></div>
                    <div className="formField"><label>Ending Boundary:</label><textarea name="endingBoundary" className="field" value={this.state.endingBoundary} onChange={this.handleInputChange} /></div>
                    <div className="formField"><label>Beginning State:</label><textarea name="startingState" className="field" value={this.state.startingState} onChange={this.handleInputChange} /></div>
                    <div className="formField"><label>Resulting State:</label><textarea name="resultingState" className="field" value={this.state.resultingState} onChange={this.handleInputChange} /></div>
                    <div className="formField"><label>Is Process?</label><input type="checkbox" name="isProcess" checked={this.state.isProcess === 1 ? "checked" : ""} onChange={this.handleInputChange} /></div>
            </Modal>
        );
    }
}

export default BusinessSystemNewModal;