import React from 'react';
import { getBezierPath, getEdgeCenter, getMarkerEnd, useReactFlow, BaseEdge } from 'reactflow';
import ProcessContext from '../BusinessProcess/processContext.js';

class RemovableEdge extends React.Component {
    static contextType = ProcessContext;

    constructor(props) {
        super(props);

        this.state = {};

        this.id = props.id;
        this.style = props.style || {};

        this.foreignObjectSize = 15;

        [this.state.edgePath, this.state.labelX, this.state.labelY] = getBezierPath(props);
        this.markerEnd = getMarkerEnd(props.arrowHeadType, props.markerEndId);
    }

    static getDerivedStateFromProps(nextProps) {
        let newState = {};

        [newState.edgePath, newState.labelX, newState.labelY] = getBezierPath(nextProps);

        return newState;
    }

    onEdgeButtonClick = (event) => {
        this.context.handleEdgeDelete(event, this.props);
    };

    render() {
        return (
            <>
                <BaseEdge
                    id={this.id}
                    style={this.style}
                    className="react-flow__edge-path"
                    path={this.state.edgePath}
                    markerEnd={this.markerEnd}
                />
                <foreignObject
                    width={this.foreignObjectSize}
                    height={this.foreignObjectSize}
                    x={this.state.labelX - this.foreignObjectSize / 2}
                    y={this.state.labelY - this.foreignObjectSize / 2}
                    className="edgebutton-foreignobject"
                    requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                    <div>
                        <button className="edgebutton" onClick={this.onEdgeButtonClick}>
                            x
                        </button>
                    </div>
                </foreignObject>
            </>
        );
    }
}

export default RemovableEdge;