import React from 'react';
import ProcessContext from '../BusinessProcess/processContext.js';

class ProcessNodeBase extends React.Component {
    constructor(props) { //{id: this.id, data: this.data, type: this.type, selected: this.selected, sourcePosition: sourcePosition, targetPosition: targetPosition}
        super();
    }

    render() {
        return (
            <>
                {
                    this.props.data?.label &&
                    <ProcessContext.Consumer>
                        { context => (
                            <span className="textContent" onClick={(e) => context.handleFlowElementClick(e, this.props)}>
                            { this.props.data?.label ? this.props.data.label.replace(/\n/g, "\n") : 'Base'}
                            </span>
                        )}
                    </ProcessContext.Consumer>
                }
                {this.props.children}
            </>
        );
    };
}

export default React.memo(ProcessNodeBase);