import React from "react";
import BusinessSystemService from '../../services/businessSystem.service';
import Modal from '../Modal';

class BusinessSystemEditModal extends React.Component {
    state = {}
    props = {}

    constructor(props) {
        super(props);

        this.props = props;

        this.state = {
            systemId: props.systemId ? props.systemId : 0,
            parentSystemId: props.parentSystemId ? props.parentSystemId : 0,
            name: props.name ? props.name : "",
            aim: props.aim ? props.aim : "",
            startingBoundary: props.startingBoundary ? props.startingBoundary : "",
            endingBoundary: props.endingBoundary ? props.endingBoundary : "",
            startingState: props.startingState ? props.startingState : "",
            resultingState: props.resultingState ? props.resultingState : "",
            isProcess: props.isProcess ? props.isProcess : 0
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.systemId !== props.systemId) {
          return {
            systemId: props.systemId ? props.systemId : 0,
            parentSystemId: props.parentSystemId ? props.parentSystemId : 0,
            name: props.name ? props.name : "",
            aim: props.aim ? props.aim : "",
            startingBoundary: props.startingBoundary ? props.startingBoundary : "",
            endingBoundary: props.endingBoundary ? props.endingBoundary : "",
            startingState: props.startingState ? props.startingState : "",
            resultingState: props.resultingState ? props.resultingState : "",
            isProcess: props.isProcess ? props.isProcess : 0
          }
        }
        return null
      }
    
    handleInputChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({isProcess: e.target.checked ? 1 : 0});
        }
        else {
            const {name, value } = e.target;
            this.setState({[name]: value});
        }
    }

    handleCancel = (e) => {
        this.props.setShow(false);
        e.stopPropagation();
    }

    handleSave = (e) => {
        e.preventDefault();
        
        try {
            const result = BusinessSystemService.update(
                this.state.systemId,
                this.state.name,
                this.state.aim,
                this.state.startingBoundary,
                this.state.endingBoundary,
                this.state.startingState,
                this.state.resultingState,
                this.state.isProcess);

            //result

            const isModalVisible = false;
            this.props.setShow(isModalVisible);
            this.props.handleUpdate(this.state, isModalVisible);
        }
        catch(ex) {
            console.log('Unhandled error in editModal.js. ' + ex)
        }
    }

    render() {
        return (
            <Modal title='Edit System' isOpen={this.props.show} dismiss={this.handleCancel} action={this.handleSave} actionText='Save'>
                <div className="formField"><label>Name:</label><textarea name="name" className="field" value={this.state.name} onChange={this.handleInputChange} /></div>
                <div className="formField"><label>Aim:</label><textarea name="aim" className="field" value={this.state.aim} onChange={this.handleInputChange} /></div>
                <div className="formField"><label>Starting Boundary:</label><textarea name="startingBoundary" className="field" value={this.state.startingBoundary} onChange={this.handleInputChange} /></div>
                <div className="formField"><label>Ending Boundary:</label><textarea name="endingBoundary" className="field" value={this.state.endingBoundary} onChange={this.handleInputChange} /></div>
                {
                    this.state.isProcess === true &&
                    <>
                        <div className="formField"><label>Starting State:</label><textarea name="startingState" className="field" value={this.state.startingState} onChange={this.handleInputChange} /></div>
                        <div className="formField"><label>Resulting State:</label><textarea name="resultingState" className="field" value={this.state.resultingState} onChange={this.handleInputChange} /></div>
                    </>
                }
                {
                    (this.state.parentSystemId && this.state.parentSystemId > 0) &&
                    <div className="formField"><label>Is Process?</label><input type="checkbox" name="isProcess" checked={this.state.isProcess === 1 ? "checked" : ""} onChange={this.handleInputChange} /></div>
                }
            </Modal>
        );
    }
}

export default BusinessSystemEditModal;