import { BusinessProcessService } from '../../services/businessProcess.service.js';

export default class BusinessProcessNode {
    constructor(nodeData) {
        //this.nodeTypeId = nodeData.nodeTypeId;
        this.id = nodeData.id;
        this.systemId = nodeData.systemId;
        this.position = nodeData.position;
        this.data = nodeData.data;

        if(nodeData.hasOwnProperty('type')) {
            this.type = nodeData.type;
            this.nodeTypeId = BusinessProcessNode.getTypeIdFromTypeString(nodeData.type);
        }
        else if(nodeData.hasOwnProperty('nodeTypeId')) {
            this.nodeTypeId = nodeData.nodeTypeId;
        }

        if (nodeData.hasOwnProperty('chartColumnId')) {
            this.chartColumnId = nodeData.chartColumnId;

            switch(nodeData.chartColumnId) {
                case 1:
                    this.className = 'alternate';
                    break;
                case 2:
                    this.className = 'valueAdded';
                    break;
                case 3:
                    this.className = 'nonValueAdded';
                    break;
                default:
                    console.log('Unrecognized chartColumnId (id=' + value + ').');
            }
        }
    }

    static async createFromService(nodeData) {
        let nodeTypeId = this.getTypeIdFromTypeString(nodeData.type);
        let serviceResults = await BusinessProcessService.createNode(
            nodeTypeId,
            nodeData.systemId,
            nodeData.position,
            nodeData.data!=null?this.cleanJSON(nodeData.data):{ label: getLabelFromTypeId(nodeData.nodeTypeId) }
            // TODO: do we need chartColumnId input?
        );

        return new BusinessProcessNode(serviceResults);
    }

    static getLabelFromTypeId(typeId) {
        switch(typeId) {
            case NodeTypes.Action:
            case NodeTypes.ActionNva:
                return 'Action';
            case NodeTypes.Start:
                return 'Start';
            case NodeTypes.Decision:
            case NodeTypes.DecisionNva:
                return 'Decision';
            case NodeTypes.Finish:
                return 'End';
            default:
                // unrecognized
                console.warn('No label for unrecognized nodeTypeId=', nodeTypeId);
                return null;
        }
    }

    static cleanJSON(json) {
        if (json && json.label) {
            json.label = json.label.replace(/[\n\r]/g, '');
        }

        return json;
    }

    static getTypeIdFromTypeString(type) {
        // TODO: resolve why we have two different string values for most node types
        switch (type) {
            case 'default':
                return 0;
            case 'start':
            case 'startNode':
                return 1;
            case 'decision':
            case 'decisionNode':
                return 2;
            case 'finish':
            case 'finishNode':
            case 'endNode':
                return 3;
            case 'nvaNodeDefault':
                return 4;
            case 'decisionNodeNva':
                return 5;
            default:
                console.warn('Unrecognized node type string: ', type);
                return null;
        }
    }

    static getTypeStringFromTypeId(nodeTypeId) {
        switch (nodeTypeId) {
            case 0:
                return 'default';
                break;
            case 1:
                return 'startNode';
                break;
            case 2:
                return 'decisionNode';
                break;
            case 3:
                return 'endNode';
                break;
            case 4:
                return 'nvaNodeDefault';
                break;
            case 5:
                return 'decisionNodeNva';
            default:
                console.warn('Unrecognized node type Id: ', nodeTypeId);
                return 'default';
        }
    }
}

export const NodeTypes = {
    Action: 0,
    Start: 1,
    Decision: 2,
    Finish: 3,
    ActionNva: 4,
    DecisionNva: 5
}