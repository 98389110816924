import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class Modal extends Component {
    constructor(props) {
        super(props);

        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown, false);
    }

    handleDismiss = (event) => {
        if (this.props.dismiss) {
            this.props.dismiss(event);
        }
    }

    handleKeyDown = (event) => {
        if(event.key === "Escape") {
            this.handleDismiss(event);
        }
    }

    handleXmarkClick = (event) => {
        this.handleDismiss(event);
    }

    render() {
        return (
            <>
            {
                this.props.isOpen && ReactDOM.createPortal(
                    <div className="modal">
                        <div className={'modal-container'+(this.props.className?' '+this.props.className:'')}>
                            <div className="xMark" onClick={this.handleXmarkClick}>x</div>
                            {
                                this.props.title &&
                                <h3>{this.props.title}</h3>
                            }
                            {
                                React.Children.map(this.props.children, (child) => {
                                    return child ? React.cloneElement(child) : null
                                })
                            }
                            {
                                this.props.showDismissButton!=false &&
                                <button className='modal-close' onClick={this.handleDismiss}>{this.props.dismissText!=null?this.props.dismissText:'Cancel'}</button>
                            }
                            {
                                this.props.showActionButton!=false &&
                                <button className='modal-close' onClick={this.props.action} disabled={this.props.actionDisabled?true:false}>{this.props.actionText!=null?this.props.actionText:'OK'}</button>
                            }
                        </div>
                    </div>
                    , document.querySelector(".stacking-context--modal")
                )
            }
            </>
        );
    }
}

export default Modal;