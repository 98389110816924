import React from 'react';
import { Outlet } from 'react-router-dom';
import SearchField from '../../components/BusinessSystemSearch/searchField.js';
import SearchResultsModal from '../../components/BusinessSystemSearch/searchResultsModal.js';
import BurgerMenu from '../../components/BurgerMenu/index.js';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchResultsModalIsVisible: false,
            searchText: '',
        }
    }

    searchResultsModalClose = () => {
        this.setState({searchResultsModalIsVisible: false, searchText: ''});
    }

    searchResultsModalOpen = (searchFieldValue) => {
        this.setState({searchResultsModalIsVisible: true, searchText: searchFieldValue});
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <h1>Apttool Web App
                        {
                            this.props.tenantName &&
                            <span className="Tenant-name"> - {this.props.tenantName}</span>
                        }
                        <div className='right-menu'>
                            {
                                this.props.isUserSignedIn &&
                                <>
                                <SearchField openResultsModal={this.searchResultsModalOpen} />
                                <SearchResultsModal isOpen={this.state.searchResultsModalIsVisible} title="Search Results" searchText={this.state.searchText} onDismiss={this.searchResultsModalClose} />
                                </>
                            }

                            <BurgerMenu isUserSignedIn={this.props.isUserSignedIn} />
                        </div>
                    </h1>
                </header>

                <div id="App-content"><Outlet /></div>

                <div className="stacking-context stacking-context--menu"></div>
                <div className="stacking-context stacking-context--modal"></div>
            </div>
        );
    }
}

export default App;