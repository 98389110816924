import React from 'react';
import Modal from '../Modal';
import { InteractiveListAction } from './action.js';

class InteractiveListItemModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getDefaultState();
    }

    componentDidMount() {
        this.setState({loading: !InteractiveListItemModal.propsAreValid(this.props)});
    }

    getActionButtonText(action) {
        switch(action) {
            case InteractiveListAction.CREATE:
                return "Create";
            case InteractiveListAction.DELETE:
                return "Delete";
            case InteractiveListAction.UPDATE:
                return "Save";
            default:
                if (!this.state.loading && this.props.isVisible) {
                    console.error(`Unexpected action in ${this.toString()}.getActionButtonText (action='${action}').`);
                }
                else {
                    return "";
                }
        }
    }

    getDefaultState() {
        return {
            loading: true,
            action: null,
            actionDisabled: false,
            item: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if (props.isVisible && InteractiveListItemModal.propsAreValid(props)) { // don't test for id change, assuming delete/update doesn't need it
            newState = {
                loading: false,
                action: props.action
                //item: props.item
            };
            if((props.action===InteractiveListAction.DELETE || props.action===InteractiveListAction.UPDATE) && state.item === null) {
                newState.item = props.item;
            }
            if(props.action===InteractiveListAction.CREATE && state.item===null) {
                // TODO: this will need to change based on the type of item being managed
                newState.item = {
                    id: 0,
                    systemId: props.systemId,
                    name: '',
                };
            }
        }

        return newState;
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name==='name') {
            let newItem = {...this.state.item, [name]:value};
            this.setState({ item: newItem });
        }
        else {
            console.log('unhandled key in handleInputChange...name=', name)
        }
    }

    handleDismiss = (e) => {
        if(this.props.onDismiss) {
            this.props.onDismiss();
        }

        this.setState(this.getDefaultState());

        e.stopPropagation();
    }

    handleAction = async (e) => {
        if(this.props.onAction) {
            const result = await this.props.onAction(this.state.item, this.state.action);

            if (this.state.action === InteractiveListAction.DELETE && !result) {
                this.setState({actionDisabled: true});

                // TODO: show message to end user
            }
        }

        this.setState(this.getDefaultState());

        e.stopPropagation();
    }

    toString() {
        return 'InteractiveListItemModal';
    }

    static propsAreValid(props) {
        //TODO: override this item in the child to be specific

        switch(props?.action) {
            case InteractiveListAction.CREATE:
                return true;
            case InteractiveListAction.DELETE:
            case InteractiveListAction.UPDATE:
                return props.item!=null;
            case null:
            default:
                return false;
        }
    }

    render() {
        return (
            <Modal className={this.props.className} title={this.state.title} isOpen={this.props.isVisible} dismiss={this.handleDismiss} action={this.handleAction} actionDisabled={this.state.actionDisabled} actionText={this.getActionButtonText(this.state.action)} showActionButton={!this.state.loading} showDismissButton={!this.state.loading}>
                {
                    (this.state.loading) &&
                    <div>Loading...</div>
                }
                {
                    (!this.state.loading && (this.state.action === InteractiveListAction.CREATE || this.state.action === InteractiveListAction.UPDATE)) &&
                    <div className="formField"><label>Name:</label><textarea name="name" className="field" value={this.state.item?this.state.item.name:""} onChange={this.handleInputChange} autoFocus={true} /></div>
                }
                {
                    (!this.state.loading && this.state.action === InteractiveListAction.DELETE) &&
                    <>
                        <div className="formField">Delete this item?</div>
                        <div className="warn">This action cannot be undone.</div>
                    </>
                }
            </Modal>
        );
    }
}

export default InteractiveListItemModal;