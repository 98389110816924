import React from 'react';

export default class ExternalLink extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <a href={this.props.url} className="externalLink" target="_blank" rel="noopener noreferrer">

            </a>
        );
    }
}