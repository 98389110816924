import Api from '../api/index.js';
import UnauthorizedError from '../api/error/Unauthorized.error';
import UrlNotFoundError from '../api/error/UrlNotFound.error';
import Cookies from 'js-cookie';

class AuthService {
    static async signin(username, password) {
        try {
            // TODO: test for valid data

            let config = {
                withCredentials: true // https://stackoverflow.com/a/42735086/1175419
            };

            let res = await Api.signin({username: username, password: password}, config, AuthService);
            if (res) {
                return res;
            }
            else {
                throw `${AuthService.toString()} signin method did not return a value from the API (url: ${error.url}).`;
            }
        } catch(error) {
            if (error instanceof UrlNotFoundError) {
                throw `${AuthService.toString()} request to endpoint was not found (url: ${error.url}).`;
            }
            else if (error instanceof UnauthorizedError) {
                //throw `${AuthService.toString()} request to endpoint was unauthorized (url: ${error.url}).`;
                throw error;
            }
            else {
                throw `${AuthService.toString()} unexpectedly failed in request to API (url: /signin). ${error.message}`;
            }
        }
    }

    static async signout(context) {
        try {
            const config = null;
            let res = await Api.signout({username: localStorage.currentUserId}, config, AuthService);
            if (res) {
                localStorage.clear();

                Cookies.remove('jwtHeaderPayload');

                if (context) {
                    context.currentUserId = null;
                    context.currentUserEmail = null;
                }
            }
            else {
                throw `${AuthService.toString()} signout method did not return a value from the API (url: ${error.url}).`;
            }
        }
        catch(ex) {
            console.error('An unhandled error occurred while signing out.', ex);
        }
    }
    
    static async signup(username, email, password) {
        try {
            // TODO: test for valid data

            let config = {
                withCredentials: true // https://stackoverflow.com/a/42735086/1175419
            };

            //TODO: handle custom display name
            let res = await Api.signup({username: username, email: email, password: password, displayName: username}, config, AuthService);
            if (res) {
                return res;
            }
            else {
                throw `${AuthService.toString()} signup method did not return a value from the API (url: ${error.url}).`;
            }
        } catch(error) {
            if (error instanceof UrlNotFoundError) {
                throw `${AuthService.toString()} request to endpoint was not found (url: ${error.url}).`;
            }
            else if (error instanceof UnauthorizedError) {
                //throw `${AuthService.toString()} request to endpoint was unauthorized (url: ${error.url}).`;
                throw error;
            }
            else {
                throw `${AuthService.toString()} unexpectedly failed in request to API (url: /signup). ${error.message}`;
            }
        }
    }
}

export default AuthService;