import axiosInstance from './axiosSetup';
import UrlNotFoundError from './error/UrlNotFound.error';
import UnauthorizedError from './error/Unauthorized.error';

class Api {
    static HttpMethod_DELETE = "DELETE";
    static HttpMethod_GET = "GET";
    static HttpMethod_POST = "POST";
    static HttpMethod_PUT = "PUT";

    static async _apiCall(method, url, data, config, implementingObject) {
        try {
            // TODO: test for valid data

            //const token = store.getState().session.token;
            // NOTE: not recommended to store jwt in localStorage
            const token = localStorage.getItem('jwtHeaderPayload');
            if (token) {
                if (!config) config = {}
                if (config.headers) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                else {
                    config.headers = {
                        Authorization: `Bearer ${token}`
                    };
                }
            }

            switch(method) {
                case Api.HttpMethod_GET:
                    return await axiosInstance.get(url, config) // GET method does not accept data in the body, assume data is in URL
                        .then((response) => { if(response!=null && response.data) return response.data; });
                case Api.HttpMethod_DELETE:
                    return await axiosInstance.delete(url, config)
                        .then((response) => { if(response!=null && response.data) return response.data; });
                        // TODO: create a standard for the response data for all delete calls
                case Api.HttpMethod_POST:
                    return await axiosInstance.post(url, data, config)
                        .then((response) => { if(response!=null && response.data) return response.data; });
                case Api.HttpMethod_PUT:
                    return await axiosInstance.put(url, data, config)
                        .then((response) => { if(response!=null && response.data) return response.data; });
                default:
                    throw new Error(`Unhandled HTTP method in _apiCall (method='${method}').`);
            }
        } catch(error) {
            if (error.response) {
                if (error.response.status === 404) {
                    throw new UrlNotFoundError(url, error, (implementingObject || Api));
                }
                else if (error.response.status === 401) {
                    throw new UnauthorizedError(url, error, (implementingObject || Api))
                }
                else {
                    console.log('unhandled error from api (status=' + error.response.status + ')');
                    console.log('data: ' + error.response.data);
                    console.log('headers: ' + error.response.headers);
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('No HTTP response was received. Request: ' + error.request);
            } else {
                console.log('An unexpected error occurred while setting up the HTTP request to the api. Error message: ', error.message);
            }

            // re-throw the error
            throw error;
        }
    }

    static async create(url, data, config, implementingObject) {
        return Api._apiCall(Api.HttpMethod_POST, url, data, config, implementingObject);
    }

    static async retrieve(url, config, implementingObject) {
        return Api._apiCall(Api.HttpMethod_GET, url, null, config, implementingObject);
    }

    static async delete(url, data, config, implementingObject) {
        return Api._apiCall(Api.HttpMethod_DELETE, url, null, config, implementingObject);
    }

    static async uploadFile(url, file, config, implementingObject) {
        try {
            if (FileReader === undefined) {
                throw new Error('No file provided for upload.');
            }

            let fd = new FormData();
            fd.append('attachment', file, 'somePDFfile');

            const defaultConfig = {
                method: 'POST',
                body: fd
            };

            return Api._apiCall(Api.HttpMethod_POST, url, fd, (config ? config : defaultConfig), implementingObject);
        } catch(error) {
            console.log('An unexpected error occurred while setting up the HTTP request to the api. Error message: ', error.message);

            // re-throw the error
            throw error;
        }
    }

    static async signin(data, config, implementingObject) {
        const url = '/auth/signin';
        
        try {
            // TODO: test for valid data
            const tempConfig = config ? config : {};
            Object.Assign(tempConfig, { withCredentials: true, data });

            return Api._apiCall(Api.HttpMethod_POST, url, data, tempConfig, implementingObject);
        } catch(error) {
            console.log('An unexpected error occurred while setting up the HTTP request to the api. Error message: ', error.message);
            
            // re-throw the error
            throw error;
        }
    }

    static async signout(data, config, implementingObject) {
        const url = '/auth/signout';
        
        try {
            // TODO: test for valid data
            const tempConfig = config ? config : {};
            Object.Assign(tempConfig, { data });

            return Api._apiCall(Api.HttpMethod_POST, url, data, tempConfig, implementingObject);
        } catch(error) {
            console.log('An unexpected error occurred while setting up the HTTP request to the api. Error message: ', error.message);
            
            // re-throw the error
            throw error;
        }
    }

    static async signup(data, config, implementingObject) {
        const url = '/auth/signup';
        
        try {
            // TODO: test for valid data
            const tempConfig = config ? config : {};
            Object.Assign(tempConfig, { withCredentials: true, data });

            return Api._apiCall(Api.HttpMethod_POST, url, data, tempConfig, implementingObject);
        } catch(error) {
            console.log('An unexpected error occurred while setting up the HTTP request to the api. Error message: ', error.message);
            
            // re-throw the error
            throw error;
        }
    }

    static async update(url, data, config, implementingObject) {
        return Api._apiCall(Api.HttpMethod_PUT, url, data, config, implementingObject);
    }
}

export default Api;