import React from 'react';
import { Link } from 'react-router-dom';

class SiblingNavMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="proximateSystemNav">
                {this.props.siblingSystems.map((s, index) => (
                    <div className={'proximateSystemNavItem'+(s.systemId.toString()===this.props.currentSystemId ? ' current' : '')} key={s.systemId}>
                        <Link to={"/" + (s.isProcess?"process":"system") + "/" + s.systemId} className={"name"}>{s.name}</Link>
                    </div>
                ))}
            </div>
        );
    }
}

export default SiblingNavMenu;