import React, { Component } from 'react';
import { Navigate, redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import AppContext from '../../containers/App/app.context.js';
import SigninManager from '../SigninManager';

export default class Authenticate extends Component {
    static contextType = AppContext;

    render() {
        // first check if already authenticated
        const userId = localStorage.getItem("userId");
        if (userId) {
            // TODO: now, verify jwt?
        }
        else {
            // need to authenticate
        }
        //const jwtHeaderPayload = Cookies.get('jwtHeaderPayload');

        //try with jwt in data/body - see SigninManager/index handleFormSubmit
        // NOTE: many recommend against storing JWT in localStorage
        const jwtHeaderPayload = localStorage.getItem("jwtHeaderPayload");

        if (jwtHeaderPayload) {
            // store payload values in local storage
            const [header, payload] = jwtHeaderPayload.split('.');
            let payloadDecrypted = JSON.parse(atob(payload)); // TODO: find alternative to atob function
            localStorage.setItem("userId", payloadDecrypted.userId);
            localStorage.setItem("email", payloadDecrypted.email);

            // set commonly used values in app context
            // TODO: figure out why this.context has no currentUserId (causing error) - see SigninManager/index handleFormSubmit
            //this.context.currentUserId = payloadDecrypted.userId;
            //this.context.currentUserEmail = payloadDecrypted.email;

            return this.props.children;
        }
        else {
            localStorage.clear();

            let location = this.props.location ? this.props.location.pathname : null;
            return <Navigate to="/signin" state={{ from: location }} />;
        }
    }
}
