import ApiBaseError from "./ApiBase.error";

class UnauthorizedError extends ApiBaseError {
    constructor(url, underlyingError, implementingObject) {
        super(url, (implementingObject || UnauthorizedError));

        this.underlyingError = underlyingError;
    }
}

export default UnauthorizedError;