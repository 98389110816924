import React from 'react';
import ReactDOM from 'react-dom';

class HoverMenuHost extends React.Component {
    static displayName = 'HoverMenuHost';

    constructor(props) {
        super(props);
        this.selectedElement =  React.createRef();
    }

    // https://stackoverflow.com/a/32623832/1175419
    absolutePosition(el) {
        const computedStyle = window.getComputedStyle(el);
        let
            found,
            left = 0,
            top = 0,
            width = 0,
            height = 0;
        if (el && el.ownerDocument === document && 'getBoundingClientRect' in el && document.body) {
            let boundingRect = el.getBoundingClientRect();
            let baseRect = document.body.getBoundingClientRect();
            found = true;
            left = boundingRect.left - baseRect.left;
            top = boundingRect.top - baseRect.top;
            width = boundingRect.right - boundingRect.left;
            height = boundingRect.bottom - boundingRect.top;
        }
        return {
            found: found,
            left: left,
            top: top,
            width: width,
            height: height,
            right: left + width,
            bottom: top + height,
            position: 'absolute'
        };
    }

    componentDidMount() {
        this.props.setPos(this.getElementBounding());
    }

    componentDidUpdate(prevProps, prevState) {
        // watching index tells us when any order changes
        // takes place (ie create, delete, move, etc.)
        if (prevProps.index != this.props.index) {
            this.props.setPos(this.getElementBounding());
        }
    }

    getElementBounding = () => {
        if (this.selectedElement && this.selectedElement.current != null) {
            const rect = this.absolutePosition(this.selectedElement.current);
            return {left: rect.left, right: rect.right, top: rect.top, height: rect.height, width: rect.width, position: rect.position};
        }
        else {
            return {left: 0, right: 0, top: 0, height: 20, width: 20, position: null};
        }
    }

    render() {
        const { children, mouseleave, mouseover, baseElement } = this.props;
        if (children) {
            if(Array.isArray(children) && children.length>0) {
                return React.cloneElement(children[0], {ref: this.selectedElement, onMouseEnter: mouseover, onMouseLeave: mouseleave});
            }
            else {
                return React.cloneElement(children, {ref: this.selectedElement, onMouseEnter: mouseover, onMouseLeave: mouseleave});
            }
        }
        else {
            return null;
        }
    }
}

export default HoverMenuHost;