import React from 'react';
import { Link } from 'react-router-dom';

class ParentButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
            {
                this.props.parentSystemId>0 &&
                <div className="parentButton">
                    <Link to={"/system/" + this.props.parentSystemId}>Parent</Link>
                </div>
            }
            </>
        );
    }
}

export default ParentButton;