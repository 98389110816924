import React from 'react';
import ReactDOM from 'react-dom';
import HoverMenuHost from './HoverMenuHost';
import HoverMenuItem from './HoverMenuItem';

class HoverMenu extends React.Component {
    state = {
        isOpen: false,
        style: {
            position: 'absolute',
            top: 0,
            left: 500
        }
    };
    
    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setPos = ({left, top, height, width, right, position}) => {
        if(this._isMounted && this.props.useStackingContext != false
|| true) {
// TODO: Figure out why isMounted is never set to true here
// TODO : figure out why useStackingContext is undefined here
            this.setState(prevState => ({style: {...prevState.style, left, top, position }}));
        }
    }

    handleListMouseOut = (event) => {
        if (this._isMounted) {
            this.setState({isOpen: false});
        }
    }

    handleHostMouseOut = (event) => {
        if(this._isMounted && event.relatedTarget.localName != 'span' && event.relatedTarget.className != 'hoverMenuItem') {
            if(event.relatedTarget.localName != 'ul' || (event.relatedTarget.localName === 'ul' && !event.relatedTarget.classList?.contains('hoverMenu'))) {
                this.setState({isOpen: false});
            }
        }
    }

    handleMouseOver = (event) => {
        if (this._isMounted) {
            this.setState({isOpen: true});
        }
    }

    //Returns true if it is a DOM element    
    isElement(o) {
        return (
            typeof HTMLElement === "object" ? o instanceof HTMLElement : //DOM2
            o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName==="string"
        );
    }

    renderBaseElement(menuChildren, classNameSuffix, style) {
        const { id, className } = this.props;
        const baseElement = React.createElement('ul', { id: id, className: (classNameSuffix ? className.concat(' ', classNameSuffix) : className), style: style, onMouseLeave: this.handleListMouseOut }, menuChildren);
        const stackingContextMenuElement = document.querySelector(".stacking-context--menu")
        if(this.props.useStackingContext !== false && this.isElement(baseElement)) {
            return ReactDOM.createPortal(
                baseElement,
                stackingContextMenuElement
            );
        }
        else {
            return baseElement;
        }
    }

    render() {
        const { isOpen } = this.state;
        const menuChildren = [];
        let baseElement = null,
            hoverMenuHost = null;

        // parse children
        this.props.children.forEach((child, index) => {
            if(child.type.displayName === HoverMenuHost.displayName) {
                hoverMenuHost = React.cloneElement(child, {mouseleave: this.handleHostMouseOut, mouseover: this.handleMouseOver, setPos: this.setPos, useStackingContext: this.props.useStackingContext, key: 'host-' + index});
            }
            else {
                menuChildren.push(React.cloneElement(child, {key: index}));
            }
        });

        if (this.props.useStackingContext != false) {
            baseElement = this.renderBaseElement(menuChildren, 'stacking-context', this.state.style);
            return (
                <div className='stackingContext'>
                {hoverMenuHost != null &&
                hoverMenuHost}
                {
                    isOpen && ReactDOM.createPortal(
                        baseElement,
                        document.querySelector(".stacking-context--menu")
                    )
                }
                </div>
            );
        }
        else {
            baseElement = this.renderBaseElement(menuChildren, 'local-context'); // UL tag
            return (
                <div className='localContext'>
                    {hoverMenuHost != null && 
                    hoverMenuHost}
                    {isOpen &&
                    baseElement}
                </div>
            );
        }
    }
}

export { HoverMenu, HoverMenuHost, HoverMenuItem };