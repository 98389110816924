import React from "react";
import BusinessSystemService from '../../services/businessSystem.service';
import Modal from '../Modal';

class BusinessSystemModal extends React.Component {
    //state = {}
    //props = {}
    static ACTION_CREATE = "create";
    static ACTION_DELETE = "delete";
    static ACTION_UPDATE = "update";

    constructor(props) {
        super(props);

        this.props = props;

        this.state = {
            loading: true,
            companyId: props.companyId ? props.companyId : 0,
            systemId: props.systemId ? props.systemId : 0,
            parentSystemId: props.parentSystemId ? props.parentSystemId : 0,
            name: props.name ? props.name : "",
            aim: props.aim ? props.aim : "",
            startingBoundary: props.startingBoundary ? props.startingBoundary : "",
            endingBoundary: props.endingBoundary ? props.endingBoundary : "",
            startingState: props.startingState ? props.startingState : "",
            resultingState: props.resultingState ? props.resultingState : "",
            isProcess: props.isProcess ? props.isProcess : 0
        }
    }

    componentDidMount() {
        this.setState({loading: false});
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.systemId !== props.systemId || (props.action && current_state.action != props.action) ) {
          return {
            action: props.action ? props.action : null,
            systemId: props.systemId ? props.systemId : 0,
            name: props.name ? props.name : "",
            aim: props.aim ? props.aim : "",
            startingBoundary: props.startingBoundary ? props.startingBoundary : "",
            endingBoundary: props.endingBoundary ? props.endingBoundary : "",
            startingState: props.startingState ? props.startingState : "",
            resultingState: props.resultingState ? props.resultingState : "",
            isProcess: props.isProcess ? props.isProcess : 0
          }
        }
        return null
      }

    getActionButtonText(action) {
        switch(action) {
            case BusinessSystemModal.ACTION_CREATE:
                return "Create";
            case BusinessSystemModal.ACTION_DELETE:
                return "Delete";
            case BusinessSystemModal.ACTION_UPDATE:
                return "Save";
            default:
                return "";
        }
    }

    getModalTitle(action) {
        switch(action) {
            case BusinessSystemModal.ACTION_CREATE:
                return "Create";
            case BusinessSystemModal.ACTION_DELETE:
                return "";
            case BusinessSystemModal.ACTION_UPDATE:
                return "Edit"
        }
    }

    handleInputChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({isProcess: e.target.checked ? 1 : 0});
        }
        else {
            const {name, value } = e.target;
            this.setState({[name]: value});
        }
    }

    handleDismiss = (e) => {
        this.props.setShow(false);
        e.stopPropagation();
    }

    handleAction = async (e) => {
        e.preventDefault();
        
        try {
            switch(this.state.action) {
                case BusinessSystemModal.ACTION_CREATE:
                    break;
                case BusinessSystemModal.ACTION_DELETE:
                    const deleteResult = await BusinessSystemService.delete(this.state.systemId);
                    if (this.props.handleDelete) {
                        this.props.handleDelete(this.state.systemId, false);
                    }
                    
                    break;
                case BusinessSystemModal.ACTION_UPDATE:
                    const result = BusinessSystemService.update(
                        this.state.systemId,
                        this.state.name,
                        this.state.aim,
                        this.state.startingBoundary,
                        this.state.endingBoundary,
                        this.state.startingState,
                        this.state.resultingState,
                        this.state.isProcess);
        
                    //result
        
                    const isModalVisible = false;
                    if (this.props.setShow) {
                        this.props.setShow(isModalVisible);
                    }

                    if (this.props.handleUpdate) {
                        this.props.handleUpdate(this.state, isModalVisible);
                    }

                    break;
            }
        }
        catch(ex) {
            console.log('Unhandled error in BusinessSystemModal. ' + ex)
        }
    }

    toString() {
        return "BusinessSystemModal";
    }

    render() {
        return (
            <Modal className={this.props.className} title={this.getModalTitle(this.state.action)} isOpen={this.props.show} dismiss={this.handleDismiss} action={this.handleAction} actionText={this.getActionButtonText(this.state.action)}>
                { (this.state.loading) &&
                    <div>Loading...</div>
                }
                {
                    (!this.state.loading && this.state.action === BusinessSystemModal.ACTION_DELETE) &&
                    <>
                        <div className="formField">Delete this process?</div>
                        <div className="warn">This action cannot be undone.</div>
                    </>
                }
                {
                    (!this.state.loading && (this.state.action === BusinessSystemModal.ACTION_CREATE || this.state.action === BusinessSystemModal.ACTION_UPDATE)) && 
                    <>
                    <div className="formField"><label>Name:</label><textarea name="name" className="field" value={this.state.name} onChange={this.handleInputChange} /></div>
                    <div className="formField"><label>Aim:</label><textarea name="aim" className="field" value={this.state.aim} onChange={this.handleInputChange} /></div>
                    <div className="formField"><label>Starting Boundary:</label><textarea name="startingBoundary" className="field" value={this.state.startingBoundary} onChange={this.handleInputChange} /></div>
                    <div className="formField"><label>Ending Boundary:</label><textarea name="endingBoundary" className="field" value={this.state.endingBoundary} onChange={this.handleInputChange} /></div>
                    {
                        this.state.isProcess === true &&
                        <>
                            <div className="formField"><label>Starting State:</label><textarea name="startingState" className="field" value={this.state.startingState} onChange={this.handleInputChange} /></div>
                            <div className="formField"><label>Resulting State:</label><textarea name="resultingState" className="field" value={this.state.resultingState} onChange={this.handleInputChange} /></div>
                        </>
                    }
                    {
                        (this.state.parentSystemId && this.state.parentSystemId > 0) &&
                        <div className="formField"><label>Is Process?</label><input type="checkbox" name="isProcess" checked={this.state.isProcess === 1 ? "checked" : ""} onChange={this.handleInputChange} /></div>
                    }
                    </>
                }
                
            </Modal>
        );
    }
}

export default BusinessSystemModal;