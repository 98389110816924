import React from 'react';
import { Handle } from 'reactflow';
import ProcessNodeBase from '../ProcessNodeBase';

class NvaProcessNodeStep extends React.Component {
  constructor(props) {
      super(props);
  }

  render() {
    return (
        <ProcessNodeBase {...this.props} data={{...this.props.data, label: this.props.data?.label ? this.props.data.label : 'NVA Step'}}>
          <Handle
            type="target"
            position="left"
            id="a"
            style={{ background: '#555' }}
          />
          <Handle
            type="target"
            position="top"
            id="c"
            style={{ background: '#555' }}
          />
          <Handle
            type="source"
            position="right"
            id="b"
            style={{ background: '#555' }}
          />
        </ProcessNodeBase>
      );
  };
}

export default NvaProcessNodeStep;