import React, {Component} from 'react';
import { useParams } from "react-router-dom";
import BusinessProcess from '../../components/BusinessProcess'
import { BusinessProcessService, getBusinessProcess } from '../../services/businessProcess.service';
import BusinessProcessNode from '../../model/BusinessProcessNode';

class BusinessProcessContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            systemId: 0,
            parentSystemId: 0,
            name: '',
            aim: '',
            chosenInputs: [],
            imposedInputs: [],
            startingBoundary: '',
            startingState: '',
            desirableOutcomes: [],
            undesirableOutcomes: [],
            endingBoundary: '',
            resultingState: '',
            nodes: [],
            nodeConnections: [],
            feedbackLoops: [],
            isProcess: false,
            loading: true
        }
    }

    componentDidMount() {
        this.loadDataToState(this.props.params?.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.id !== this.props.params.id) {
            this.loadDataToState(this.props.params.id);
        }
    }

    loadDataToState(systemId) {
        getBusinessProcess(systemId)
            .then((response) => {
                this.setState({
                    systemId: systemId,
                    parentSystemId: response.parentSystemId || 0,
                    name: response.name || '',
                    aim: response.aim || '',
                    siblingSystems: response.siblingSystems || [],
                    nodes: response.nodes || [],
                    nodeConnections: response.nodeConnections || [],
                    startingBoundary: response.startingBoundary || '',
                    endingBoundary: response.endingBoundary || '',
                    startingState: response.startingState || '',
                    resultingState: response.resultingState || '',
                    chosenInputs: response.chosenInputs || [],
                    imposedInputs: response.imposedInputs || [],
                    feedbackLoops: response.feedbackLoops || [],
                    desirableOutcomes: response.desirableOutcomes || [],
                    undesirableOutcomes: response.undesirableOutcomes || [],
                    isProcess: response.isProcess || false,
                    loading: false
                });

                if (!response) {
                    console.debug('container response is empty ', response)
                }
            })
            .catch(function(e) { console.log('Error while retrieving business process: ', e); });
    }

    handleEdgesChange = (appliedEdgeChanges) => {
        this.setEdges(appliedEdgeChanges);
    }

    handleNodesChange = (appliedNodeChanges) => {
        this.setNodes(appliedNodeChanges);
    }

    handleNodeCreate = async (createdNode) => {
        const nodeModel = await BusinessProcessNode.createFromService(createdNode);

        this.setState({nodes: [...this.state.nodes, nodeModel]});
    }

    handleNodeDelete = async (nodeId) => {
        const result = await BusinessProcessService.deleteNode(nodeId);
        if (result === true) {
            // now try to delete locally
            const newNodes = this.state.nodes.filter(item => item.id !== nodeId.toString());
            const newConnections = this.state.nodeConnections.filter(item => (item.source !== nodeId && item.target !== nodeId));
            this.setState({nodes: newNodes, nodeConnections: newConnections});
        }
    }

    handleNodeUpdate = async (updatedNode) => {
        const result = await BusinessProcessService.updateNode(
            updatedNode.id,
            updatedNode.type || 'default',
            updatedNode.position,
            {
                label: (updatedNode.data && updatedNode.data.label ? updatedNode.data.label : ''),
                ynposid: updatedNode.data?.ynposid,
                externalUrl: updatedNode.data?.externalUrl,
            }
        );

        const updateNodes = this.state.nodes.map((node) => {
            if (node.id === updatedNode.id) {
                node.data = {
                    ...node.data,
                    label: updatedNode.data.label,
                    ynposid: updatedNode.data.ynposid,
                    externalUrl: updatedNode.data?.externalUrl,
                };
            }

            return node;
        });

        this.setState({nodes: updateNodes});
    }

    handleNodeConnectionCreate = async (connection) => {
        let newConnection = await BusinessProcessService.createNodeConnection(connection.source, connection.sourceHandle, connection.target, connection.targetHandle);

        const newNodeConnections = [...this.state.nodeConnections, newConnection];
        this.setEdges(newNodeConnections);
    }

    handleNodeConnectionDelete = async (edgeId) => {
        const result = await BusinessProcessService.deleteNodeConnection(edgeId);

        // TODO: ensure that deleteNodeConnection call was successful before removing locally
        if (this.state?.hasOwnProperty('nodeConnections')) {
            const newEdges = this.state.nodeConnections.filter(edge => edge.id != edgeId);
            this.setEdges(newEdges);
        }
    }

    setEdges = (eds) => {
        this.setState({nodeConnections: eds});
    }

    setNodes = (nds) => {
        this.setState({nodes: nds});
    }

    render() {
        return (
            <div className="businessProcessContainer">
                {
                    this.state.loading &&
                    <p>loading Process...</p>
                }
                {
                    this.state.nodes &&
                    <BusinessProcess
                        systemId={this.state.systemId}
                        parentSystemId={this.state.parentSystemId}
                        name={this.state.name}
                        aim={this.state.aim}
                        siblingSystems={this.state.siblingSystems}
                        chosenInputs={this.state.chosenInputs}
                        imposedInputs={this.state.imposedInputs}
                        startingBoundary={this.state.startingBoundary}
                        startingState={this.state.startingState}
                        desirableOutcomes={this.state.desirableOutcomes}
                        undesirableOutcomes={this.state.undesirableOutcomes}
                        endingBoundary={this.state.endingBoundary}
                        resultingState={this.state.resultingState}
                        nodes={this.state.nodes}
                        nodeConnections={this.state.nodeConnections}
                        feedbackLoops={this.state.feedbackLoops}
                        isProcess={this.state.isProcess}
                        onNodeConnectionCreate={this.handleNodeConnectionCreate}
                        onNodeConnectionDelete={this.handleNodeConnectionDelete}
                        onNodeCreate={this.handleNodeCreate}
                        onNodeUpdate={this.handleNodeUpdate}
                        onNodeDelete={this.handleNodeDelete}
                        onEdgesChange={this.handleEdgesChange}
                        onNodesChange={this.handleNodesChange}
                    />
                }
            </div>
        );
    }
}

const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let params = useParams();
        return (
            <Component
                {...props}
                params={params}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter(BusinessProcessContainer);