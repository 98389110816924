import Api from '../api';

class SystemIOService {
    static async createInput(systemInputData) {
        const result = await this.createFromAPI('/SystemInput', systemInputData);
        return result;
    }

    static async createOutcome(systemOutcomeData) {
        const result = await this.createFromAPI('/SystemOutcome', systemOutcomeData);
        return result;
    }

    static async delete(itemId, ioType) {
        return this.deleteFromAPI(`${this._getBaseUrlForIoType(ioType)}/${itemId}`);
    }

    static _getBaseUrlForIoType(ioType) {
        switch(ioType) {
            case "input":
                return "/SystemInput";
            case "outcome":
                return "/SystemOutcome";
            default:
                console.log('Unexpected IO type', ioType);
                return "";
        }
    }

    static async updateInput(systemInputData) {
        const result = await this.updateFromAPI(`/SystemInput/${systemInputData.id}`, systemInputData);
        return result;
    }

    static async updateOutcome(systemOutcomeData) {
        const result = await this.updateFromAPI(`/SystemOutcome/${systemOutcomeData.id}`, systemOutcomeData);
        return result;
    }

    static async createFromAPI(url, systemIOData) {
        try {
            // TODO: test for valid data

            return Api.create(url, systemIOData);
        } catch(e) {
            throw `${SystemIOService.toString()} createFromAPI unexpectedly failed in request to API (url: ${url}). Error details: ${e}`;
        }
    }

    static async deleteFromAPI(url) {
        return Api.delete(url, null, SystemIOService);
    }

    static async retrieve(itemId, ioType) {
        return this.retrieveFromAPI(`${this._getBaseUrlForIoType(ioType)}/${itemId}`);
    }

    static async retrieveFromAPI(itemId) {
        const url = `/FeedbackLoop/${itemId}`;
        return Api.retrieve(url, null, SystemIOService);
    }

    static async updateFromAPI(url, systemIOData) {
        try {
            // TODO: test for valid data

            return await Api.update(url, systemIOData);
        } catch(e) {
            throw `${SystemIOService.toString()} updateFromAPI unexpectedly failed in request to API (url: ${url}). Error details: ${e}`;
        }
    }

    static toString() {
        return 'SystemIOService';
    }
}

export default SystemIOService