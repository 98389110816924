import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import './css/style.scss';
import ClientRouter from './components/ClientRouter';

if (module.hot) {
  module.hot.accept();
}

if(process.env.NODE_ENV === 'production') {
    console.log('Environment: Production');
} else {
    console.log('Environment: Development');
}

ReactDOM.render(<ClientRouter />, document.getElementById('root'));