import React, { cloneElement } from "react";
import { PERMISSIONS, ROLES } from "./permissionMaps";

export default class AuthorizeGate extends React.Component {
    constructor(props) {
        super(props);
    }

    static getRole = () => {
        const role = localStorage.getItem('roleId');
        return (role ? role : null);
    }

    hasPermission_old = ({ permissions, scopes }) => {
        const scopesMap = {};
        this.props.scopes.forEach((scope) => {
          scopesMap[scope] = true;
        });
      
        return permissions.some((permission) => scopesMap[permission]);
    };

    static hasPermission = (role, scopes ) => {
        if (role === 'undefined' || role === null) { return []; }
        const permissions = PERMISSIONS[role];
        const scopesMap = {};
        scopes.forEach((scope) => {
          scopesMap[scope] = true;
        });
      
        return permissions.some((permission) => scopesMap[permission]);
    };

    RenderError = () => {
        return this.props.RenderError ? this.props.RenderError : <></>;
    }

    errorProps = () => {
        return this.props.errorProps ? this.props.errorProps : null;
    }
    
    scopes = () => {
        return this.props.scopes ? this.props.scopes : [];
    }

    render() {
        const role = AuthorizeGate.getRole();
        const permissionGranted = (role ? AuthorizeGate.hasPermission(role, this.scopes()) : false);

        if (!permissionGranted && !this.errorProps()) return this.RenderError();

        if (!permissionGranted && this.errorProps())
            return cloneElement(this.props.children, { ...this.errorProps() });

        return <>{this.props.children}</>;
    }
}