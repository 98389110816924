import React from 'react';

export default () => {
    const GHOST_ELEMENT_ID = {
        default: 'drag-ghost-default',
        defaultNva: 'drag-ghost-nva',
        decision: 'drag-ghost-decision',
        decisionNva: 'drag-ghost-decision-nva',
        start: 'drag-ghost-start',
        end: 'drag-ghost-end'
    };

    const createGhostElement = (id, className) => {
        let g = document.createElement("div");
        g.id = id;
        g.textNode = "Dragging";
        g.style.position = "absolute";
        g.style.top = "-1000px";
        g.style.width = '300px';
        if (className) { g.classList.add(className); }
        document.body.appendChild(g);
        return g;
    };

    const deleteGhostElement = (id) => {
        let ghost = document.getElementById(id);
        if (ghost && ghost.parentNode) {
            ghost.parentNode.removeChild(ghost);
        }
    };

    const onDragStart = (event, nodeType) => {
        let elem = createGhostElement(GHOST_ELEMENT_ID.default);
        let elemnva = createGhostElement(GHOST_ELEMENT_ID.defaultNva, 'react-flow__node-nvaNodeDefault');
        let elemDecision = createGhostElement(GHOST_ELEMENT_ID.decision);
        let elemDecisionNva = createGhostElement(GHOST_ELEMENT_ID.decisionNva, 'react-flow__node-decisionNode');

        let ghost = null;
        switch (nodeType) {
            case 'default':
                ghost = elem;
                //ghost = elem.cloneNode(true);
                break;
            case 'decisionNode':
                ghost = elemDecision;
                //ghost = elemDecision.cloneNode(true);
               break;
            case 'nvaNodeDefault':
                ghost = elemnva;
                //ghost = elemnva.cloneNode(true);
                break;
            case 'decisionNodeNva':
                ghost = elemDecisionNva;
                break;
            case 'startNode':
                ghost = createGhostElement(GHOST_ELEMENT_ID.start);
                break;
            case 'endNode':
                ghost = createGhostElement(GHOST_ELEMENT_ID.end);
                break;
            default:
              console.warn('onDragStart unrecognized node type: ', nodeType);
        }

        if (ghost) {
            const offset = getOffsetBasedOnNodeType(nodeType);
            event.dataTransfer.setDragImage(ghost, offset.x, offset.y);
            //document.body.appendChild(ghost);
        }

        // do this either way
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    const onDrag = (event, nodeType) => {
        let ghost = null;
        switch (nodeType) {
            case 'default':
                ghost = document.getElementById(GHOST_ELEMENT_ID.default);
                toggleClassIfNvaColumn(ghost, event.clientX, 'react-flow__node-nvaNodeDefault');
                break;
            case 'decisionNode':
                ghost = document.getElementById(GHOST_ELEMENT_ID.decision);
                toggleClassIfNvaColumn(ghost, event.clientX, 'nonValueAdded');
                break;
            case 'nvaNodeDefault':
                ghost = document.getElementById(GHOST_ELEMENT_ID.defaultNva)?.cloneNode(true);
                break;
            case 'startNode':
                ghost = document.getElementById(GHOST_ELEMENT_ID.start);
                toggleClassIfNvaColumn(ghost, event.clientX, 'nonValueAdded');
                break;
            case 'endNode':
                ghost = document.getElementById(GHOST_ELEMENT_ID.end);
                toggleClassIfNvaColumn(ghost, event.clientX, 'nonValueAdded');
                break;
            default:
                console.warn('onDrag unrecognized node type: ', nodeType);
        }
    };

    const onDragEnd = (event) => {
        deleteGhostElement(GHOST_ELEMENT_ID.default);
        deleteGhostElement(GHOST_ELEMENT_ID.decision);
        deleteGhostElement(GHOST_ELEMENT_ID.defaultNva);

        // NOTE: Commenting out the code below because the HTML DnD spec does not allow
        // modification of the dataTransfer items in onDragEnd.
        // https://html.spec.whatwg.org/dev/dnd.html#concept-dnd-p
        /*
        event.dataTransfer.clearData('application/reactflow');

        let img = new Image();
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
        event.dataTransfer.setDragImage(img, 0, 0);
        */
    };

    const getOffsetBasedOnNodeType = (nodeTypeString) => {
        switch (nodeTypeString) {
            case 'decisionNode':
            case 'decisionNodeNva':
                return {
                    x: 150,
                    y: 36
                };
            case 'default':
                return {
                    x: 150,
                    y: 15
                };
            case 'startNode':
            case 'endNode':
                return {
                    x: 58,
                    y: 58
                };
            default:
                console.warn('Unrecognized node type for computing offset: ', nodeTypeString);
                return {
                    x: 0,
                    y: 0
                };
        }
    };

    const toggleClassIfNvaColumn = (ghost, clientX, className) => {
        const nvaColumnBegin = 640;
        
        if (!ghost) { return; }

        if (clientX > nvaColumnBegin && !ghost.classList.contains(className)) {
            ghost.classList.add(className);
        }

        if (clientX < nvaColumnBegin && ghost.classList.contains(className)) {
            ghost.classList.remove(className);
        }
    };

  return (
    <aside>
      <div className="description">You can drag these nodes to the pane below.</div>
      <ul className="dndnodemenu">
        <li
          className="dndnode start"
          onDrag={(event) => onDrag(event, 'startNode')}
          onDragStart={(event) => onDragStart(event, 'startNode')}
          onDragEnd={(event) => onDragEnd(event)}
          title="Start node"
          draggable>
        </li>
        <li
          className="dndnode action"
          onDrag={(event) => onDrag(event, 'default')}
          onDragStart={(event) => onDragStart(event, 'default')}
          onDragEnd={(event) => onDragEnd(event)}
          title="Action node"
          draggable>
        </li>
        <li
          className="dndnode decision"
          onDrag={(event) => onDrag(event, 'decisionNode')}
          onDragStart={(event) => onDragStart(event, 'decisionNode')}
          onDragEnd={(event) => onDragEnd(event)}
          title="Decision node"
          draggable>
        </li>
        <li
          className="dndnode finish"
          onDrag={(event) => onDrag(event, 'endNode')}
          onDragStart={(event) => onDragStart(event, 'endNode')}
          onDragEnd={(event) => onDragEnd(event)}
          title="Finish node"
          draggable>
        </li>
      </ul>
    </aside>
  );
};