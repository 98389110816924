import FeedbackLoopService from '../services/feedbackLoop.service';

class FeedbackLoop {
    constructor(id, systemId, name) {
        this.id = (id ? id : 0);
        this.systemId = (systemId ? systemId : 0);
        this.name = (name ? name : '');
    }

    static async create(feedbackLoopData) {
        if (!feedbackLoopData.systemId || feedbackLoopData.systemId===0) {
            throw new Error('Missing or inapropriate systemId value. Cannot create FeedbackLoop item. item=', feedbackLoopData);
        }

        const result = await FeedbackLoopService.create(feedbackLoopData);
        if (result.id && result.id > 0 && result.systemId && result.systemId > 0 && result.name.length != '') {
            let feedbackLoop = new FeedbackLoop(result.id, result.systemId, result.name);
            return feedbackLoop;
        }
        else {
            throw new Error('FeedbackLoop.create did not receive valid input. feedbackLoopData=', feedbackLoopData);
        }
    }

    static async delete(itemId) {
        return await FeedbackLoopService.delete(itemId);
    }

    static async update(feedbackLoopData) {
        const result = await FeedbackLoopService.update(feedbackLoopData);

        let feedbackLoop = null;
        feedbackLoop = new FeedbackLoop(result.id, result.systemId, result.name);
        feedbackLoop.id = result.id;

        return feedbackLoop;
    }

    static toString() {
        return 'FeedbackLoop';
    }
}

export default FeedbackLoop;