class SystemIO {
    static TYPE_INPUT = "input";
    static TYPE_OUTCOME = "outcome";
    static SUBTYPE_CHOSEN_INPUT = "chosen";
    static SUBTYPE_IMPOSED_INPUT = "imposed";
    static SUBTYPE_DESIRABLE_OUTCOME = "desirable";
    static SUBTYPE_UNDESIRABLE_OUTCOME = "undesirable";
    
    constructor(systemId, name) {
        this.id = 0;
        this.systemId = systemId || 0;
        this.name = name || '';
    }

    static createFromService() {
        throw new Error('This method should be overridden by child object.');
    }

    static toString() {
        return 'SystemIO';
    }
}

export default SystemIO;