import React, { Component } from 'react'

export default class PasswordInputField extends Component {
  render() {
    return (
        <div className="formField">
            <label htmlFor={this.props.name}>{this.props.label}</label>
            <input
                name={this.props.name}
                type='password'
                onChange={this.props.handleInputChange}
                onKeyUp={this.props.handleValidation}
                value={this.props.value}
                placeholder={this.props.placeholder ? this.props.placeholder : "Password"}
            />
            <span className="validationMessage">{this.props.validationMessage}</span>
        </div>
    )
  }
}
