import React from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { processDomain } from '../../services/multitenant.service';
import App from '../../containers/App';
import TenantContext from '../../containers/App/tenant.context.js';
import BusinessSystemContainer from '../../containers/BusinessSystemContainer';
import BusinessProcessContainer from '../../containers/BusinessProcessContainer';
import SigninManager from '../SigninManager';
import SignoutManager from '../SignoutManager';
import SignupManager from '../SignupManager';
import '../../i18n';
import Authenticate from '../Authenticate';

class ClientRouter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clientId: 0,
            name: '',
            subdomain: '',
            domain: '',
            rootSystemId: 0,
            loading: true,
            isUserSignedIn: false
        };

        this.handleSignout = this.handleSignout.bind(this);
    }

    componentDidMount() {
        processDomain()
            .then((response) => this.setState({...response, loading: false, isUserSignedIn: !!localStorage.getItem('userId')}))
            .catch(function(e) { console.log('Error while processing domain: ', e); }); 
    }

    handleSignin = () => {
        this.setState({isUserSignedIn: true});
    }

    handleSignout = () => {
        this.setState({isUserSignedIn: false});
    }

    handleSignup = () => {
        //
    }

    render() {
        if (this.state.clientId > 0 && !this.state.loading && this.state.rootSystemId > 0) {
            return (
                <TenantContext.Provider value={this.state.clientId}>
                    <BrowserRouter>
                        <Routes>
                            <Route element={<App tenantName={this.state.name} clientId={this.state.clientId} isUserSignedIn={this.state.isUserSignedIn}/>}>
                                <Route path="signin" element={<SigninManager handleSignin={this.handleSignin} />} />
                                <Route path="signout" element={<SignoutManager handleSignout={this.handleSignout} />} />
                                <Route path="signup" element={<SignupManager handleSignup={this.handleSignup} />} />
                                <Route path="*" element={<Navigate to={"/system/"+this.state.rootSystemId} replace />} />
                                <Route path="/system" element={<Navigate to={"/system/"+this.state.rootSystemId} replace />} />
                                <Route path="/system/:id" element={<Authenticate><BusinessSystemContainer /></Authenticate>} />
                                <Route path="/process/:id" element={<Authenticate><BusinessProcessContainer /></Authenticate>} />
                                <Route path="*" element={<div>oops</div>} />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </TenantContext.Provider>
            );
        }
        else {
            return (
                <p>loading...</p>
            );
        }
    }
}

export default ClientRouter;