import SystemIO from './SystemIO.js';
import SystemIOService from '../services/systemIO.service.js';

class SystemInput extends SystemIO {
    constructor(systemId, name, isImposed) {
        super(systemId, name);

        this.isImposed = (isImposed == true);
    }

    static async createFromService(systemInputData) {
       const result = await SystemIOService.createInput(systemInputData);

       var systemInput = null;
       systemInput = new SystemInput(result.systemId, result.name, result.isImposed);
       systemInput.id = result.id;

       return systemInput;
    }

    static async update(systemInputData) {
        const result = await SystemIOService.updateInput(systemInputData);

        let systemInput = null;
        systemInput = new SystemInput(result.systemId, result.name, result.isImposed);
        systemInput.id = result.id;

        return systemInput;
    }

    static async delete(itemId) {
        return await SystemIOService.delete(itemId, SystemIO.TYPE_INPUT);
    }

    static toString() {
        return 'SystemInput';
    }
}

export default SystemInput;