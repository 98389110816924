import React from 'react';
import { Handle } from 'reactflow';
import ProcessNodeBase from '../ProcessNodeBase';
class ProcessNodeEnd extends React.Component {
  constructor(props) {
      super(props);
  }

  render() {
    return (
        <ProcessNodeBase {...this.props} data={{...this.props.data, label: this.props.data?.label ? this.props.data.label : 'Finish'}}>
          <Handle
            type="target"
            position="top"
            id="e"
            style={{ background: '#555' }}
          />
        </ProcessNodeBase>
      );
  };
}

export default ProcessNodeEnd;