import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            common: {
                button: {
                    cancel: {
                        text: 'Cancel'
                    },
                    ok: {
                        text: 'OK'
                    },
                    save: {
                        text: 'Save'
                    },
                    update: {
                        text: 'Update'
                    },
                    delete: {
                        text: 'Delete'
                    },
                    search: {
                        text: 'Search'
                    }
                },
                mainMenu: {
                    items: {
                        homeItem: 'Home',
                        authItem: {
                            signin: 'Sign in',
                            signout: 'Sign out'
                        }
                    }
                },
                userGreeting: {
                    message: 'Hello <strong>{{displayName}}</strong>!'
                },
                months: [
                    {
                        name: 'January',
                        abbreviation: 'Jan',
                        abbrWithDayOfMonth: 'Jan {{day}}',
                        number: 1
                    },
                    {
                        name: 'February',
                        abbreviation: 'Feb',
                        abbrWithDayOfMonth: 'Feb {{day}}',
                        number: 2
                    },
                    {
                        name: 'March',
                        abbreviation: 'Mar',
                        abbrWithDayOfMonth: 'Mar {{day}}',
                        number: 3
                    },
                    {
                        name: 'April',
                        abbreviation: 'Apr',
                        abbrWithDayOfMonth: 'Apr {{day}}',
                        number: 4
                    },
                    {
                        name: 'May',
                        abbreviation: 'May',
                        abbrWithDayOfMonth: 'May {{day}}',
                        number: 5
                    },
                    {
                        name: 'June',
                        abbreviation: 'Jun',
                        abbrWithDayOfMonth: 'Jun {{day}}',
                        number: 6
                    },
                    {
                        name: 'July',
                        abbreviation: 'Jul',
                        abbrWithDayOfMonth: 'Jul {{day}}',
                        number: 7
                    },
                    {
                        name: 'August',
                        abbreviation: 'Aug',
                        abbrWithDayOfMonth: 'Aug {{day}}',
                        number: 8
                    },
                    {
                        name: 'September',
                        abbreviation: 'Sep',
                        abbrWithDayOfMonth: 'Sep {{day}}',
                        number: 9
                    },
                    {
                        name: 'October',
                        abbreviation: 'Oct',
                        abbrWithDayOfMonth: 'Oct {{day}}',
                        number: 10
                    },
                    {
                        name: 'November',
                        abbreviation: 'Nov',
                        abbrWithDayOfMonth: 'Nov {{day}}',
                        number: 11
                    },
                    {
                        name: 'December',
                        abbreviation: 'Dec',
                        abbrWithDayOfMonth: 'Dec {{day}}',
                        number: 12
                    }
                ],
                object: {
                    fields: {
                        id: {
                            label: 'Id:'
                        },
                        name: {
                            label: 'Name:'
                        },
                        createdDate: {
                            label: 'Created:'
                        },
                        createdByUserId: {
                            label: 'Created by:'
                        },
                        detail: {
                            header: 'Detail'
                        }
                    }
                }
            },
            auth: {
                signin: {
                    form: {
                        button: 'Sign in',
                        password: { label: 'Password'},
                        username: { label: 'Username'},
                        unauthorizedMessage: 'Username or password is not valid.'
                    }
                },
                signout: {
                    message: 'You have been signed out.',
                    signInAgainLink: {
                        message: 'Sign in again.'
                    }
                },
                signup: {
                    form: {
                        button: 'Sign up',
                        password: { label: 'Password'},
                        passwordVerify: { label: 'Password verify'},
                        username: { label: 'Username'},
                        unauthorizedMessage: 'Username or password is not valid.'
                    }
                },
            },
            organizations: {
                modal: {
                    title: {
                        header: 'Organizations'
                    }
                },
                selector: {
                    single: {
                        label: 'Organization:'
                    }
                }
            }
        }
      }
    }
  });

export default i18n;
