import { getConfig } from './config.service';
import SystemConfig from 'SystemConfig';

function processDomain() {
    const parseResult = {
        clientId: 0,
        name: '',
        subdomain: '',
        domain: '',
        rootSystemId: 0
    };
    
    return new Promise((resolve, reject) => {
        try {
            // figure out client ID
            const clientUrl = new URL(window.location.href);
            const clientHostname = clientUrl.hostname;
            const hostnameParts = clientHostname.split('.', 10);
            const baseDomain = SystemConfig.multitenant.baseDomain;

            console.log(`Parsing domain ${clientHostname}`);

            if (!baseDomain) { reject('Unable to load the base domain configuration.'); }
            
            parseResult.subdomain = hostnameParts.length>0?hostnameParts[0]:'';
            parseResult.domain = clientHostname.replace(parseResult.subdomain + '.', '');

            // TODO: pull domain from config file
            if (!parseResult.domain || parseResult.domain!=baseDomain) {
                reject(`The ${parseResult.domain} domain is not a valid domain (baseDomain='${baseDomain}').`);
            } else if (parseResult.subdomain) {

                try {
                    getConfig(parseResult.subdomain)
                        .then(function(data) {
                            if (data) {
                                // TODO: remove parseResult assignments below if no longer needed, test passing data only
                                parseResult.clientId = data.clientId;
                                parseResult.name = data.name;

                                resolve(data);
                            } else {
                                reject(`no data for subdomain \"${parseResult.subdomain}\"`);
                            }
                        })
                        .catch(function(e) {
                            if (e.response.status == 404) {
                                reject('API: Unable to obtain the client configuration.');
                            } else {
                                reject('API: Unknown error. ', e);
                            }
                        });
                    
                }
                catch(e) {
                    reject(`Unexpected fail during call to getConfig. Error details: ${e}`);
                }
            }
        }
        catch(e) {
            reject(`Unexpected fail during call to parse tenant domain. Error details: ${e}`);
        }
    })
}

export { processDomain };