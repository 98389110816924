import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import AuthService from '../../services/auth.service.js';
import AppContext from '../../containers/App/app.context.js';
import './signoutManager.css';

class SignoutManager extends React.Component {
    static contextType = AppContext;

    componentDidMount() {
        AuthService.signout(this.context);

        if (this.props.handleSignout) {
            this.props.handleSignout();
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className='signOut'>
                <p>{t('auth.signout.message', 'You have been signed out.')}</p>
                <p><Link to='/signin'>{t('auth.signout.signInAgainLink.message', 'Sign in again.')}</Link></p>
            </div>
        );
    }
}

export default withTranslation()(function(props) {
    const navigate = useNavigate();

    return <SignoutManager {...props} navigate={navigate} />;
});