import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import PasswordInputField from '../PasswordInputField';
import { withTranslation } from 'react-i18next';

class PasswordConfirmValidated extends Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordError: "",
            confirmPasswordError: "",
            passwordInput: {
                password:'',
                confirmPassword:''
            },
            isValid: false,
        }
    }

    handlePasswordChange =(evnt)=>{
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;

        const NewPasswordInput = {...this.state.passwordInput,[passwordInputFieldName]:passwordInputValue}
        this.setState({passwordInput: NewPasswordInput});

        if (this.props.onChange && this.state.isValid) {
            this.props.onChange({target: {name: 'password', value: NewPasswordInput.password}});
        }
    }

    handleValidation= (evnt)=>{
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;

        // password requirements
        if(passwordInputFieldName==='password'){
            const uppercaseRegExp   = /(?=.*?[A-Z])/;
            const lowercaseRegExp   = /(?=.*?[a-z])/;
            const digitsRegExp      = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            const minLengthRegExp   = /.{8,}/;
            const passwordLength =      passwordInputValue.length;
            const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
            const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
            const digitsPassword =      digitsRegExp.test(passwordInputValue);
            const specialCharPassword = specialCharRegExp.test(passwordInputValue);
            const minLengthPassword =   minLengthRegExp.test(passwordInputValue);

            // TODO convert error messages to i18n
            let errMsg ="";
            if(passwordLength===0){
                    errMsg="Password is empty";
            }else if(!uppercasePassword){
                    errMsg="At least one Uppercase";
            }else if(!lowercasePassword){
                    errMsg="At least one Lowercase";
            }else if(!digitsPassword){
                    errMsg="At least one digit";
            }else if(!specialCharPassword){
                    errMsg="At least one Special Characters";
            }else if(!minLengthPassword){
                    errMsg="At least minumum 8 characters";
            }else{
                errMsg="";
            }
            this.setState({passwordError: errMsg});
            this.setState({isValid: (errMsg==="")});
        }

        // for confirm password
        if(passwordInputFieldName=== "confirmPassword" || (passwordInputFieldName==="password" && this.state.passwordInput.confirmPassword.length>0) ){
            if(this.state.passwordInput.confirmPassword!==this.state.passwordInput.password) {
                // TODO convert error msg to i18n
                //setConfirmPasswordError("Confirm password is not matched");
                this.setState({confirmPasswordError: "Confirm password is not matched"});
            }
            else {
                //setConfirmPasswordError("");
                this.setState({confirmPasswordError: ""});
            }
        }
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <PasswordInputField
                    name="password"
                    label={t('auth.signup.form.password.label', 'Password')}
                    handleInputChange={this.handlePasswordChange}
                    handleValidation={this.handleValidation}
                    value={this.state.passwordInput.password}
                    validationMessage={this.state.passwordError}
                />
                <PasswordInputField
                    name="confirmPassword"
                    label={t('auth.signup.form.password.labelConfirm', 'Confirm password')}
                    handleInputChange={this.handlePasswordChange}
                    handleValidation={this.handleValidation}
                    value={this.state.passwordInput.confirmPassword}
                    validationMessage={this.state.confirmPasswordError}
                />
            </>
        )
    }
}

export default withTranslation()(function(props) {
    const navigate = useNavigate();

    return <PasswordConfirmValidated {...props} navigate={navigate} />;
});