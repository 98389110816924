import React from 'react';
import { Link } from 'react-router-dom';

class BurgerMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuIsVisible: false
        }
    }

    onClick = (e) => {
        this.setState({menuIsVisible: !this.state.menuIsVisible})
    }

    onMouseLeave = (e) => {
        this.setState({menuIsVisible: false});
    }

    render() {
        return (
            <div className='burger-menu' onClick={this.onClick}>&nbsp;
            {
                this.state.menuIsVisible &&
                <ul onMouseLeave={this.onMouseLeave}>
                    {
                        this.props.isUserSignedIn===true &&
                        <li><div className='signout'><Link to={"/signout"}>Sign Out</Link></div></li>
                    }
                    {
                        this.props.isUserSignedIn!=true &&
                        <li><div className='signin'><Link to={"/signin"}>Sign In</Link></div></li>
                    }
                </ul>
            }
            </div>
        );
    }
}

export default BurgerMenu;