import Api from '../api';

function getBusinessProcess(systemId) {
    console.warn('BusinessProcessService getBusinessProcess(systemId) has been deprecated.');
    return BusinessProcessService.retrieveOne(systemId);
}

function getBusinessProcessNodes(systemId) {
    console.warn('BusinessProcessService getBusinessProcessNodes(systemId) has been deprecated.');
    return BusinessProcessService.retrieveMultipleNodes(systemId);
}

class BusinessProcessService {
    //TODO: separate node methods into separate service
    static async createNode(nodeTypeId, systemId, position, data) {
        return BusinessProcessService.createNodeFromTypeId(systemId, nodeTypeId, position, data);
    }

    static async createNodeFromTypeString(systemId, typeString, position, data) {
        return BusinessProcessService.createNodeFromTypeId(systemId, this.getTypeIdFromTypeString(typeString), position, data);
    }

    static async createNodeFromTypeId(systemId, typeId, position, data) {
        const url = '/ProcessNode';
        try {
            // TODO: test for valid data
        
            const node = {
                systemId: systemId,
                nodeTypeId: typeId,
                position: position,
                data: data
            };

            const res = await Api.create(url, node, null, BusinessProcessService);
            res.type = BusinessProcessService.getTypeStringFromTypeId(typeId);
            return res;
        } catch(error) {
            throw `${BusinessProcessService.toString()} unexpectedly failed in request to API (url: ${url}). ${error}`;
        }
    }

    static async createNodeFromModel(node) {
        return BusinessProcessService.createNodeFromTypeId(node.systemId, node.typeId, node.position, node.data);
    }

    static async nodeAttachFile(nodeId, file) {
        const url = `/ProcessNode/${nodeId}/file`;

        // TODO: test for valid data

        Api.uploadFile(url, file, null, BusinessProcessService)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            throw error;
        });
    }

    static toString() {
        return 'BusinessProcessService';
    }

    static async createNodeConnection(source, sourceHandle, target, targetHandle) {
        let createResult;

        const url = `/ProcessNodeConnection`;
        try {
            // TODO: test for valid ID

            const connection = {
                source: source,
                sourceHandle: sourceHandle,
                target: target,
                targetHandle: targetHandle
            };
 
            createResult = await Api.create(url, connection);

            if (!createResult) {
                throw 'Error creating Business Process Node Connection';
            }

            return createResult;
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }
    }

    static async retrieveOne(systemId) {
        const url = `/Process/${systemId}`;
        return Api.retrieve(url, null, BusinessProcessService);
    }

    static async retrieveMultipleNodes(systemId) {
        const url = `/Process/${systemId}/Nodes`;
        try {
            return Api.retrieve(url, null, BusinessProcessService);
        } catch(e) {
            reject(`Unexpected fail in request to server. Error details: ${e}`);
        }
    }

    static async update(systemId, name, aim, startingBoundary, endingBoundary) {
        var updateResult;

        const url = `/Process/${systemId}`;
        try {
            // TODO: test for valid ID

            const system = {
                name: name,
                aim: aim,
                startingBoundary: startingBoundary,
                endingBoundary: endingBoundary,
                startingState: startingState,
                resultingState: resultingState
            };

            updateResult = await Api.update(url, system);

            if (!updateResult) {
                throw 'Error updating Business Process';
            }
    
            return updateResult;
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }
    }

    static async updateNode(nodeId, type, position, data) {
        let updateResult;

        const url = `/ProcessNode/${nodeId}`;
        try {
            // TODO: test for valid ID

            const node = {
                nodeId: nodeId,
                nodeTypeId: this.getTypeIdFromTypeString(type),
                position: position,
                data: data
            };

            updateResult = await Api.update(url, node);

            if (!updateResult) {
                throw 'Error updating Business Process Node';
            }
    
            return updateResult;
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }
    }

    static async deleteNode(nodeId) {
        let result = null;

        const url = `/ProcessNode/${nodeId}`;
        try {
            // TODO: test for valid ID

           const result = await Api.delete(url);

           return result;
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }
    }

    static async deleteNodeConnection(nodeConnectionId) {
        let result = false;

        const url = `/ProcessNodeConnection/${nodeConnectionId}`;
        try {
            // TODO: test for valid ID

            return await Api.delete(url);
        } catch(e) {
            throw `Unexpected fail in request to server (url: ${url}). Error details: ${e}`;
        }

        return result;
    }

    static getTypeIdFromTypeString(type) {
        // TODO: resolve why we have two different string values for most node types
        switch (type) {
            case 'default':
                return 0;
            case 'start':
            case 'startNode':
                return 1;
            case 'decision':
            case 'decisionNode':
                return 2;
            case 'finish':
            case 'finishNode':
            case 'endNode':
                return 3;
            case 'nvaNodeDefault':
                return 4;
            case 'decisionNodeNva':
                return 5;
            default:
                console.warn('Unrecognized node type string: ', type);
                return null;
        }
    }

    static getTypeStringFromTypeId(nodeTypeId) {
        switch (nodeTypeId) {
            case 0:
                return 'default';
                break;
            case 1:
                return 'startNode';
                break;
            case 2:
                return 'decisionNode';
                break;
            case 3:
                return 'endNode';
                break;
            case 4:
                return 'nvaNodeDefault';
                break;
            case 5:
                return 'decisionNodeNva';
            default:
                console.warn('Unrecognized node type Id: ', nodeTypeId);
                return 'default';
        }
    }
}

export {
    getBusinessProcess,
    getBusinessProcessNodes,
    BusinessProcessService
}