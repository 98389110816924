import React from 'react';
import { BusinessProcessService } from '../../services/businessProcess.service';

class ProcessNodeMenu extends React.Component {
    constructor(props) {
        super(props);

        this.fileInputRef = React.createRef();
    }

    handleClick = (event) => {
        if (event.target.id==='attach') {
            this.handleAttachClick();
        }
        else {
            this.props.onClick(event);
        }
    }

    handleFileChange = (event) => {
        if (event.target.files) {
            BusinessProcessService.nodeAttachFile(this.props.id, event.target.files[0])
                .then((result) => {
                    //console.log('success', result)
                })
                .catch((ex) => {
                    //console.log('faile', ex)
                });

            this.props.onNodeAttach(this.props.id);
        }
    }

    handleAttachClick = (event) => {
        this.fileInputRef.current.click();
    }

    onFileChangeCapture = (event) => {
        //
    }

    toString() {
        return 'ProcessNodeMenu';
    }

    render() {
        return (
            <ul
                style={{ top: this.props.top, left: this.props.left }}
                className={"context-menu" + (this.props.type ? " " + this.props.type : "")}
                onClick={this.handleClick}
            >
                { process.env.NODE_ENV === 'development' &&
                <li className="debug">node: {this.props.id}</li>
                }
                <li id="attach" className="attach" onClick={() => {this.handleAttachClick}}>
                    External Application
                    <input type="file" name="attachment" onChange={this.handleFileChange} style={{display:'none'}} ref={this.fileInputRef} onChangeCapture={this.onFileChangeCapture} />
                </li>
                <li className="delete" onClick={() => {this.props.onNodeDelete(this.props.id)}}>Delete Element</li>
                <li className="cancel" onClick={() => {this.props.onNodeMenuCancel()}}>Cancel</li>
            </ul>
        );
    }
}

export default ProcessNodeMenu;