import SystemIO from './SystemIO.js';
import SystemIOService from '../services/systemIO.service.js';

class SystemOutcome extends SystemIO {
    constructor(systemId, name, isUndesirable) {
        super(systemId, name);

        this.isUndesirable = (isUndesirable == true);
    }

    static async createFromService(data) {
        const result = await SystemIOService.createFromAPI('/SystemOutcome', data);
 
        let systemOutcome = null;
        systemOutcome = new SystemOutcome(result.systemId, result.name, result.isUndesirable);
        systemOutcome.id = result.id;
 
        return systemOutcome;
    }

     static async update(iOSystemData) {
        const result = await SystemIOService.updateOutcome(iOSystemData);
 
        let iOSystem = null;
        iOSystem = new SystemOutcome(result.systemId, result.name, result.isUndesirable);
        iOSystem.id = result.id;
 
        return iOSystem;
    }

    static async delete(itemId) {
        return await SystemIOService.delete(itemId, SystemIO.TYPE_OUTCOME);
    }

    static toString() {
        return 'SystemOutcome';
    }
}

export default SystemOutcome;